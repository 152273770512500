let host = process.env.GATSBY_WEBSITE_URL;

export const prependHost = (link: string | null | undefined) => {
  if (link == null) return '';
  if (link.startsWith('http')) return link;

  const seperator = link.startsWith('/') ? '' : '/';

  const replacedLink = link.replace(/\/\//g, '/');

  return `${host}${seperator}${replacedLink}`;
};
