/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Thing } from 'schema-dts';
import { isNotNull } from '../../utils/isNotNull';
import { decodeHTML } from '../../utils/decodeHTML';
import { prepareDescription } from '../../utils/seo/prepareDescription';

const WP_URL = process.env.GATSBY_WORDPRESS_URL;
const SITE_URL = process.env.GATSBY_WEBSITE_URL;

export type SeoProps = {
  description?: string;
  lang?: string;
  meta?: any;
  ogType?: string;
  title: string;
  microData?: Thing[];
};

// TODO: Add logo to microdata
export const Seo: React.FC<SeoProps> = ({
  description = '',
  lang = 'sv',
  meta = [],
  ogType = 'website',
  title,
  microData = [],
}) => {
  const { site } = useStaticQuery(
    graphql`
      query SeoQuery {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  );

  const parsedDescription = prepareDescription(description || '');

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: parsedDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: parsedDescription,
        },
        ogType
          ? {
              property: `og:type`,
              content: ogType,
            }
          : null,
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: parsedDescription,
        },
      ]
        .filter(isNotNull)
        .concat(meta)}
    >
      <link rel="dns-prefetch" href={WP_URL} />
      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "Techarenan",
          "logo": {
            "@type": "ImageObject",
            "url": "",
            "width": 0,
            "height": 0
          },
          "url": "${SITE_URL}"
        }
      `}</script>
      {microData.map((data, index) => (
        <script
          key={`${(data as any)['@type']}${index}`}
          type="application/ld+json"
        >
          {JSON.stringify(data, null, 2)}
        </script>
      ))}
      <script id="mcjs" type="text/javascript">
        {`
          !function(c,h,i,m,p){
            m = c.createElement(h), p = c.getElementsByTagName(h)[0], m.async = 1, m.src = i, p.parentNode.insertBefore(m, p)
          }
          (document,"script","https://chimpstatic.com/mcjs-connected/js/users/20a77303b2e02239366ffabd6/4baa2cde3c3679325ed4f9cfe.js");
        `}
      </script>
      <script type="text/javascript">
        {`
          try {
            window.dojoRequire(
              ["mojo/signup-forms/Loader"],
              function(L) {
                L.start({"baseUrl":"mc.us3.list-manage.com","uuid":"20a77303b2e02239366ffabd6","lid":"b2a73d812b","uniqueMethods":true})
              }
            )
          } catch(e) {
            console.warn('MC_ERR', e);
          }
        `}
      </script>
    </Helmet>
  );
};
